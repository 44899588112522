import 'Css/application.sass'
import '../images/mfi-logo.png'

import { createApp } from 'vue'
import store from 'Js/store'
import Components from 'Components'
import Directives from 'Js/directives'
import config from 'Js/app/config'

// Make it all interactive with Vue
var app

requestAnimationFrame(() => {
  app = createApp(config).use(store)
})

requestAnimationFrame(() => {
  for(const key in Components) {
    app.component(key, Components[key])
  }
})

requestAnimationFrame(() => {
  for(const dir in Directives) {
    app.directive(dir, Directives[dir])
  }
})

requestAnimationFrame(() => {
  app.mount('#app')
})
